import React, { useState } from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import { Row, Col, Container, Form, Button, Alert } from "react-bootstrap";
import ReactMarkdown from "react-markdown/with-html";
import facebook from "../img/social/facebook.svg";
import twitter from "../img/social/twitter.svg";
import linkedin from "../img/social/linkedin.svg";
import logo from "../../static/img/frog_logo_gray_small.jpg";
import axios from "axios";
import iconPhone from "../../static/img/phone.svg";
import iconEmail from "../../static/img/mail.svg";
export const FooterComponentTemplate = ({ contactInfo }) => {
  const [formData, updateFormData] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const handleChange = (e) => {
    updateFormData({
      ...formData,

      // Trimming any whitespace
      [e.target.name]: e.target.value.trim(),
    });
  };
  const handleSubmit = (event) => {
    const form = event.currentTarget;
    console.log(process.env.SERVER_URL);
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      axios
        .post(`${process.env.SERVER_URL}/newsletter`, {
          values: formData,
        })
        .then((res) => {
          setSubmitted(true);
        });
    }
  };
  return (
    <footer className="footer has-background-black">
      <Container fluid>
        <Row className="d-flex flex-row justify-content-center">
          <Col
            xs={12}
            lg={4}
            xl={2}
            className="mt-3 mt-lg-0 text-center text-lg-left d-flex flex-row justify-content-center justify-content-lg-end"
          >
            <ul className="pl-0">
              <li>
                <Link to="/adas">ADAS Calibration Equipment</Link>
              </li>
              <li>
                <Link to="/cut-out-lines">Cut-Out Lines</Link>
              </li>
              <li>
                <Link to="/glass-part-lifting-device">Glass Part Lifting Device</Link>
              </li>
              <li>
                <Link to="/gear">Gear</Link>
              </li>
              <li>
                <Link to="">Technical Support</Link>
              </li>
              <li>
                <Link to="/training-videos">Training Videos</Link>
              </li>
            </ul>
          </Col>
          <Col
            xs={12}
            lg={2}
            className="px-lg-5 mx-lg-5 mb-3 mb-lg-0 d-flex flex-row justify-content-center align-items-center"
          >
            <Link to="/">
              <img src={logo} alt="FrogItOut" style={{ width: "75px", height: "75px" }} />
            </Link>
          </Col>
          <Col xs={12} lg={4} xl={2} className="d-flex flex-column align-items-center align-items-lg-start">
            <h3>CONTACT US</h3>
            <p>
              <img
                src={iconPhone}
                alt="phone call"
                style={{ width: "1.1em", height: "1.1em", paddingBottom: "2px", filter: "invert(70%)" }}
                className="mr-2"
              />
              <a href="tel:2096020734">209-602-0734</a>
              <br />
              <img
                src={iconEmail}
                alt="email"
                style={{ width: "1.1em", height: "1.1em", paddingBottom: "2px", filter: "invert(70%)" }}
                className="mr-2"
              />
              <a href="mailto:info@frogitout.com">info@frogitout.com</a>
            </p>
            <div className="">
              <a title="linkedin" href="" target="_blank" rel="noreferrer">
                <img src={linkedin} alt="LinkedIn" style={{ width: "1.5em", height: "1.5em" }} className="mr-2" />
              </a>
              <a title="twitter" href="" target="_blank" rel="noreferrer">
                <img src={twitter} alt="Twitter" style={{ width: "1.5em", height: "1.5em" }} className="mr-2" />
              </a>
              <a title="facebook" href="" target="_blank" rel="noreferrer">
                <img src={facebook} alt="Facebook" style={{ width: "1.5em", height: "1.5em" }} className="mr-2" />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

const FooterComponent = (props) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          markdownRemark(frontmatter: { templateKey: { eq: "footer-component" } }) {
            frontmatter {
              contactInfo
            }
          }
        }
      `}
      render={({ markdownRemark }) => {
        const { frontmatter } = markdownRemark;
        return <FooterComponentTemplate contactInfo={frontmatter.contactInfo} {...props} />;
      }}
    />
  );
};

export default FooterComponent;
